<!-- 教学与考试场地 -->
<template>
    <div class="teachingField">
       <el-form ref="form" :model="form" inline class="form" id="form" label-width="90px">
           <!-- <el-form-item label="场地编号">
               <el-input v-model="form.number" placeholder="请输入场地编号"></el-input>
           </el-form-item> -->
           <el-form-item label="场地名称">
               <el-input v-model="form.name" placeholder="请输入场地名称"></el-input>
           </el-form-item>
           <el-form-item label="所属机构">
                <el-select v-model="form.belonging" filterable placeholder="请选择所属机构" :clearable="true">
                    <el-option
                        v-for="item in form.belongingChoose"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
          <el-form-item label="类型">
              <el-select v-model="form.type" placeholder="请选择类型" :clearable="true">
                  <el-option
                      v-for="item in form.typeChoose"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
              </el-select>
          </el-form-item> 
           <el-form-item label="">
               <div class="" style="margin-left: 22px;">
                   <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                       查询
                   </el-button>
                   <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="close">
                       重置
                   </el-button>
               </div>
           </el-form-item>
       </el-form>
       <div class="" style="display: flex;justify-content: space-between;">
           <div class="" style="margin-left:22px ;">
               <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                   @click="refresh">
                   <!-- 刷新 -->
               </el-button>
               <el-button size="small" icon="el-icon-plus" style="background: #18BC9C;color: #FFFFFF;"
                   @click="newlyAdded">
                   新增
               </el-button>
           </div>
           
       </div>
       <!-- 表格 -->
       <el-table :data="tableData"  border stripe style="width:97.9%;flex: 1;" height="650" @sort-change="abc">
           <el-table-column prop="site_number" sortable="custom" label="场地编号" align="center" width="120">
           </el-table-column>
           <el-table-column prop="status"  sortable="custom" label="状态" align="center" width="80">
               <template slot-scope="scope">
                   <span v-if="scope.row.status==1">可用</span>
                   <span v-else-if="scope.row.status==2">停用</span>
               </template>
           </el-table-column>
           <el-table-column prop="site_name" sortable="custom" label="场地名称" align="center" >
           </el-table-column>
           <el-table-column prop="student_num" sortable="custom" label="学员数" align="center" width="100">
           </el-table-column>
           <el-table-column prop="pid" label="上级场地" sortable="custom" align="center" width="200">
           </el-table-column>
           <el-table-column prop="start_time" sortable="custom" label="启用日期" align="center" >
           </el-table-column>
           <el-table-column prop="end_time" sortable="custom" label="停用日期" align="center" >
           </el-table-column>
           <el-table-column prop="org_id" label="所属机构" sortable="custom" align="center" width="200">
           </el-table-column>
           <el-table-column  label="操作"  align="center" width="200">
               <template slot-scope="scope">
                   <el-button icon="el-icon-edit" size="mini" style="background: #18BC9C;color:#FFFFFF;" @click="edit(scope.row.id)">
                       编辑
                   </el-button>
                   <el-button type="danger" icon="el-icon-delete" size="mini" @click="dlt(scope.$index,scope.row.id)">
                       删除
                   </el-button>
               </template>
           </el-table-column>
       </el-table>
       <!--分页 -->
       <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
           :page-sizes="[20,50, 100,200,500,1000]" :page-size="10" background
           layout="total, sizes, prev, pager, next, jumper" :total="total">
       </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                    number:"",//场地编号
                    name:"",//场地名称
                    nameChoose:[],//场地名称选择
                    belonging:"",//所属机构
                    belongingChoose:[],//所属机构选择
                    type:"",//类型
                    typeChoose:[
                        {
                            label: "学院场地",
                            value: "1"
                        }, {
                            label: "校外考点",
                            value: "2"
                        }, {
                            label: "其它",
                            value: "3"
                        }
                    ]//类型选择   
                },
                tableData: [],
                currentPage: 1,
				currentLimit: 20,
                total: 0,
                arr:[]
            }
        },
        created() {
           this.getList()
           // 选择机构
           this.$request({
               url: "/api/mechanism/list",
               method: "POST",
               data: {
                   page: 1,
                   limit: 1000
               }
           }).then(res => {
               // console.log("选择机构",res.data.list)
               this.form.belongingChoose = res.data.list
           })
        },
        activated() {
           // console.log(1); 
           this.getList()
        },
        methods:{
			getList() {
				this.$request({url: '/api/schoolarea/list', method: 'POST', data: {
					page: this.currentPage,
					limit: this.currentLimit,
					site_name: this.form.name,//场地名称
					org_id: this.form.belonging,//所属机构
					type: this.form.type,//类型
                    sort:this.arr
				}}).then(res => {
					console.log(res)
					this.tableData = res.data.list
					this.total = res.data.count
					console.log(this.tableData)
				})
			},
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
				this.currentLimit = val
				this.getList()
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
				this.currentPage=val
				this.getList()
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
                this.arr=[]
            },
            newlyAdded() { //新增
                this.$router.push({
                    path: "addTeachingField"
                })
            },
            edit(id) { //编辑
                this.$router.push({
                    path: "editTeachingField",
					query: {
					    id: id
					}
                })
            },
			dlt(index, id) { //删除
				var that = this
				this.$confirm('是否删除此条记录?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
					that.$request({url: '/api/schoolarea/del', method: 'POST', data: {id}}).then(res => {
						if(res.code == 1) {
							that.$message({
							  message: res.msg,
							  type: 'success'
							});
							that.tableData.splice(index, 1) 
						}
					})
				})
				
			},
			query() { //查询
                this.currentPage=1
				this.getList()
			},
			close() {  //重置
				this.form.number=""//场地编号
				this.form.name=""//场地名称
				this.form.belonging=""//所属机构
				this.form.condition=""//状态
                this.arr=[]
                this.getList()
			},
            //排序
            abc(i){ 
                console.log(i)
                this.tableData=[]
                this.arr=[]
                this.arr.push({
                    title:i.prop,
                    order:i.order
                })
                console.log(this.arr,111)
                this.getList()
            }
        }
    }
</script>

<style scoped="scoped">
    .teachingField{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .form {
         padding-top: 34px;
       /*  display: flex;
         justify-content: space-between;
         flex-wrap: wrap; */
     }
     
    .form ::v-deep .el-input__inner {
         width: 276px;
         height: 37px;
     }
     
     .form ::v-deep .el-form-item__label {
         color: #000000;
     }
     /* 按钮 */
     ::v-deep .el-icon-refresh {
         font-weight: bold !important;
         color: #FFFFFF;
     }
     
     ::v-deep .el-icon-plus {
         font-weight: bold !important;
     }
     
     ::v-deep .el-icon-download {
         font-weight: bold !important;
     }
     /* 表格 */
     .el-table {
         margin: 20px;
     }
     ::v-deep .el-table__cell {
         color: #000;
         padding: 0;
         height: 30px;
     }
     /* 分页 */
     .el-pagination {
         margin: 20px;
         margin-top: 0px;
     }
     
     ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
         background-color: #2C3E50 !important;
     }
</style>
